<template>
    <!--begin::Layout-->
    <div v-if="clinic" class="d-flex flex-column flex-lg-row">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
        <Details
          v-if="tabs.selected === 'Details'"
          :clinic="clinic"
          @edit="openEditDetails"
        />
        <WebsitePresentations
          v-if="tabs.selected === 'Website presentations'"
          :clinic="clinic"
          @presentationApproved="fetchClinic"
          @presentationChanged="fetchClinic"
          @edit="openEditDetails"
        />
        <NearbyHotels
          v-if="tabs.selected === 'Nearby hotels'"
          :clinic="clinic"
        />
      </div>
      <!--end::Content-->
  
      <!--begin::Sidebar-->
      <div
        class="
          flex-column flex-lg-row-auto
          w-lg-250px w-xl-300px
          mb-10
          order-1 order-lg-2
        "
      >
        <Summary
          :clinic="clinic"
          :tabs="tabs"
          @select="selectTab"
        />
      </div>
      <!--end::Sidebar-->
  
      <NewClinic
        name="new-clinic-modal"
        v-model="isNewClinicModalOpen"
        @cancel="closeEditDetails"
        @confirm="onClinicUpdated"
      >
        <template v-slot:title>Edit clinic</template>
      </NewClinic>
    </div>
    <!--end::Layout-->
  </template>
  
  <script>
  import Details from "@/components/clinics/Details.vue";
  import WebsitePresentations from "@/components/clinics/WebsitePresentations.vue";
  import NearbyHotels from "@/components/clinics/NearbyHotels.vue";
  import Summary from "@/components/clinics/Summary.vue";
  import NewClinic from "@/components/modals/clinics/NewClinic.vue";
  import { StickyComponent } from "@/assets/ts/components";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { useToast } from "vue-toastification";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  
  import ApiService from "@/core/services/ApiService";
  
  export default {
    components: {
      Details,
      WebsitePresentations,
      NearbyHotels,
      Summary,
      NewClinic,
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        isEditingDetails: false,
        tabs: {
          selected: "Details",
          items: ["Details", "Website presentations", "Nearby hotels"],
        },
        clinic: null,
        isNewClinicModalOpen: false,
      };
    },
    mounted() {
      this.fetchClinic();
      setCurrentPageBreadcrumbs("View Clinic", ["Clinics"]);
      this.$nextTick(() => {
        StickyComponent.reInitialization();
      });
    },
    methods: {
      openEditDetails() {
        this.$vfm.show("new-clinic-modal", this.clinic);
      },
      closeEditDetails() {
        this.isNewClinicModalOpen = false;
      },
      onClinicUpdated(updatedClinic) {
        this.$vfm.hide("new-clinic-modal");
        this.fetchClinic();
      },
      fetchClinic() {
        ApiService.get("/clinics", this.$route.params.id)
          .then(({ data: clinic }) => {
            console.log(clinic);
            this.clinic = clinic;
          })
          .catch((err) => {
            // this.isTableLoading = false;
          });
      },
      selectTab(tab) {
        this.tabs.selected = tab;
      },
    },
  };
  </script>
  