<template>
  <div class="card card-flush mb-0">
    <div class="card-header">
      <div class="card-title">
        <h2>Clinic</h2>
      </div>
    </div>

    <div class="card-body pt-0 fs-6">
      <div class="separator separator-dashed mb-7"></div>
      <p v-for="(item, idx) in tabs.items" :key="idx" class="text-gray-600 mb-4 tab" @click="$emit('select', item)"
        :class="{ 'active-tab': tabs.selected === item }">
        <!-- <i class="bi bi-arrow-right-short me-1" v-if="tabs.selected === item"></i> -->
        {{ item }}
      </p>
    </div>
  </div>

  <div class="card card-flush mt-7">
    <div class="card-body fs-6">
      <div class="mb-4">
        <h5 class="mb-4">Quick actions</h5>
      </div>

      <div class="mb-0 d-grid">
        <a :href="`mailto:${clinic.email}`" class="btn btn-primary mb-4">
          Send an email to clinic
        </a>
        <a :href="`tel:${clinic.phone}`" class="btn btn-primary mb-4">
          Call clinic
        </a>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Object,
      required: true,
    },
  },
  emits: ['select'],
  computed: {
    clinicType() {
      return this.clinic.clinic ? 'Patient' : 'Prospect';
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  border: 1px solid #dddee4 !important;
}

.tab {
  cursor: pointer;
}

.active-tab {
  font-weight: bolder !important;
  color: #2237F0 !important;
}
</style>
