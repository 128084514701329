<template>
    <div class="card card-flush pt-3 mb-5 mb-xl-10">
        <div class="card-header">
            <div class="card-title">
                <h2 class="fw-bolder me-6">
                    {{ clinic.name }}<br/>
                    <small>Nearby hotels</small>
                </h2>
            </div>
        </div>

        <div class="card-body pt-3">
            <div class="mb-8">
                <div v-for="hotel in hotels" :key="hotel.id">
                    <form v-if="editingHotels.includes(hotel.id) || !hotel.id" class="form d-flex flex-wrap pt-5 mb-10" enctype="multipart/form-data" @submit.prevent="saveHotel(hotel)">
                        <div class="me-6" style="max-width: 250px;">
                            <div class="mb-2">
                                <label>Hotel picture</label>
                                <input
                                    :disabled="isLoading"
                                    @change="handleImageUpload(hotel.id, $event.target.files[0])"
                                    type="file"
                                    class="form-control"
                                    accept="image/jpeg,image/png"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="mb-2">
                                <label class="required">
                                    Hotel name
                                </label>
                                <input v-model="hotel.name" type="text" class="form-control" placeholder="" required />
                            </div>
                            <div class="mb-2">
                                <label class="required">
                                    Stars
                                </label>
                                <input v-model="hotel.stars" type="number" class="form-control" placeholder="" min="0" step="1" max="6" required />
                            </div>
                            <div class="mb-2">
                                <label class="required">
                                    Distance from clinic (km)
                                </label>
                                <input v-model="hotel.distance" type="number" class="form-control" placeholder="" min="0" step="0.1" required />
                            </div>
                            <div class="mb-2">
                                <label class="required">
                                    Link (e.g. Google Maps, hotel's website)
                                </label>
                                <input v-model="hotel.link" type="url" class="form-control" placeholder="" required />
                            </div>
                                
                            <div class="d-flex">
                                <button type="submit" class="btn btn-primary btn-sm me-2">
                                    <span class="indicator-label">Save</span>
                                    <span class="indicator-progress">
                                        Please wait...
                                        <span
                                            class="spinner-border spinner-border-sm align-middle ms-2"
                                        ></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>

                    <div v-else class="d-flex flex-wrap pt-5 mb-10">
                        <div class="me-6" style="max-width: 250px;">
                            {{ hotel.blobSrc }}
                            <img v-if="hotel.picture && hotel.picture.filename" class="img-fluid" :src="`${apiUrl}/hotels-images/${hotel.picture.filename}`">
                            <span v-else>No image.</span>
                        </div>
                        <div>
                            <h3>{{ hotel.name }}</h3>
                            <p>{{ hotel.stars }} stars</p>
                            <p>Distance: {{ hotel.distance }} km</p>
                            <p>Link: <a :href="hotel.link" target="_blank">{{ hotel.link }}</a></p>
                            <div class="d-flex">
                                <button @click.prevent="editHotel(hotel.id)" class="btn btn-primary btn-sm me-2">
                                    <span class="indicator-label">Edit</span>
                                </button>
                                <button @click.prevent="deleteHotel(hotel.id)" class="btn btn-secondary btn-sm">
                                    <span class="indicator-label">Delete</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="separator separator-dashed mb-10"></div>
                </div>
            </div>
            <button @click.prevent="addHotel" class="btn btn-primary btn-sm me-2">
                <span class="indicator-label">Add a nearby hotel</span>
            </button>
        </div>
    </div>
</template>
  
<script>
import ApiService from "@/core/services/ApiService";

export default {
    props: {
        clinic: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hotels: [],
            editingHotels: [],
            hotelsImagesUploading: [],
            hotelsImages: [],
            isLoading: false,
      		apiUrl: process.env.VUE_APP_API_URL,
        }
    },
    computed: {
        defaultAddress() {
            return this.clinic.addresses.find(e => e.label == "DEFAULT");
        },
    },
    created() {
        this.fetchHotels();
    },
    methods: {
        editHotel(id) {
            this.editingHotels.push(id)
        },
        addHotel() {
            this.hotels.push({
                clinic: this.clinic.id,
                name: "",
                distance: null,
                stars: null,
                link: "",
                picture: {},
            });
        },
        // Fetch hotels (pictures restricted with JWT header access, src url -> blob url)
        fetchHotels() {
            ApiService.query(`/clinics-hotels?clinic=${this.clinic.id}&limit=200`)
                .then(({ data }) => {
                    this.hotels = data.results;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
		handleImageUpload(hotelId, file) {
            this.hotelsImagesUploading.push(hotelId);
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!allowedTypes.includes(file.type)) {
				alert("Only jpeg and png images are allowed");
                this.hotelsImagesUploading = this.hotelsImagesUploading.filter(e => e != hotelId);
				return;
			}
			if (file.size > 2 * 1000000) { // Max. 2Mo
				alert("File too large, max. 2Mo");
                this.hotelsImagesUploading = this.hotelsImagesUploading.filter(e => e != hotelId);
				return;
			}
            this.hotelsImages = this.hotelsImages.filter(e => e.hotelId != hotelId);
            this.hotelsImages.push({ hotelId, image: file });
            this.hotelsImagesUploading = this.hotelsImagesUploading.filter(e => e != hotelId);
		},
        saveHotel(hotel) {
            console.log("saving hotel -> ", hotel);
            if (hotel.id) {
                // Update
                const hotelImage = this.hotelsImages.find(e => e.hotelId == hotel.id);
                if (hotelImage) {
                    const formData = new FormData();
                    formData.append("image", hotelImage.image);
                    ApiService.update("clinics-hotels", hotel.id, formData, {
                        responseToast: {
                            text: "Hotel's picture successfully uploaded",
                        }
                    });
                }

                ApiService.update("clinics-hotels", hotel.id, hotel, {
                    responseToast: {
                        text: "Hotel successfully updated",
                    }
                })
                    .then(({ data }) => {
                        console.log("created hotel -> ", data);
                        this.editingHotels = this.editingHotels.filter(e => e != hotel.id);
                        this.fetchHotels();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                // Create
                ApiService.post(`/clinics-hotels`, hotel, {
                    responseToast: {
                        text: "Hotel successfully created",
                    }
                })
                    .then(({ data }) => {
                        const hotelImage = this.hotelsImages.find(e => e.hotelId == hotel.id);
                        if (hotelImage) {
                            const formData = new FormData();
                            formData.append("image", hotelImage.image);
                            ApiService.update("clinics-hotels", data.id, formData, {
                                responseToast: {
                                    text: "Hotel's picture successfully uploaded",
                                }
                            }).then(() => {
                                this.fetchHotels();
                            });
                        } else {
                            this.fetchHotels();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        // Delete hotel
        deleteHotel(id) {
            ApiService.delete(`/clinics-hotels/${id}`)
                .then(() => {
                    this.hotels = this.hotels.filter(e => e.id != id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
