<template>
    <div class="card card-flush pt-3 mb-5 mb-xl-10">
        <div class="card-header">
            <div class="card-title">
                <h2 class="fw-bolder me-6">
                    {{ clinic.name }}<br />
                    <small>Website presentations</small>
                </h2>
            </div>
        </div>

        <div class="card-body pt-3">
            <div class="mb-8">
                <div class="d-flex flex-row align-items-center justify-content-between mb-4">
                    <h3 class="mb-0">Clinic presentation</h3>
                </div>

                <div class="d-flex flex-wrap pt-5 mb-10">
                    <template v-if="!clinic.presentationDraft.approved">
                        <div class="flex-equal">
                            <h6>⚠️ Presentation pending review</h6>
                            <img v-if="clinic.presentationDraft.image" class="img-fluid my-2" style="max-height: 100px;"
                                :src="`${apiUrl}/clinics-images/${clinic.presentationDraft.image}`">
                            <span v-else>No image</span>
                            <p>
                                <strong>Description</strong><br>
                                <span
                                    v-if="clinic.presentationDraft && clinic.presentationDraft.description"
                                    v-html="clinic.presentationDraft.description.replace(/\n/g, '<br />')"
                                />
                            </p>
                            <p>
                                <strong>Spoken languages</strong><br>
                                {{ clinic.presentationDraft.spokenLanguages.join(', ').toUpperCase() }}
                            </p>
                        </div>
                        <div class="flex-equal align-self-center">
                            <button @click.prevent="() => approvePresentation('clinic', clinic.id)"
                                class="btn btn-secondary btn-sm d-block mx-auto text-center">
                                Approve modifications<br>
                                <i class="bi bi-arrow-right"></i>
                            </button>
                        </div>
                    </template>
                    <div class="flex-equal">
                        <div v-if="!editingClinicPresentation">
                            <h6 class="me-2">
                                ℹ️ Current presentation on website
                                <small v-if="clinic.presentationDraft.approved">
                                    -
                                    <a href="#" @click.prevent="editClinicPresentation()" class="btn btn-sm btn-link">
                                        Edit
                                    </a>
                                </small>
                            </h6>
                            <img v-if="clinic.presentation.image" class="img-fluid my-2" style="max-height: 100px;"
                                :src="`${apiUrl}/clinics-images/${clinic.presentation.image}`">
                            <span v-else>No image</span>
                            <p>
                                <strong>Description</strong><br>
                                <span
                                    v-if="clinic.presentation && clinic.presentation.description"
                                    v-html="clinic.presentation.description.replace(/\n/g, '<br />')"
                                />
                            </p>
                            <p
                                v-for="translatedDescription in clinic.presentation.translatedDescription"
                                :key="`desc-${translatedDescription.language}`"
                            >
                                <strong>Description ({{ translatedDescription.language }})</strong><br>
                                <span
                                    v-html="translatedDescription.description.replace(/\n/g, '<br />')"
                                />
                            </p>
                            <p>
                                <strong>Spoken languages</strong><br>
                                {{ clinic.presentation.spokenLanguages.join(', ').toUpperCase() }}
                            </p>
                        </div>
                        <div v-else>
                            <form class="form" enctype="multipart/form-data" @submit.prevent="saveClinicPresentation()">
                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label fs-6 fw-bold">
                                        Picture<br />
                                        <span class="text-muted">
                                            A picture that represents the clinic
                                        </span>
                                    </label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-10">
                                                <input :disabled="isUploading"
                                                    @change="handleClinicImageUpload($event.target.files[0])"
                                                    type="file" class="form-control" accept="image/jpeg,image/png" />
                                            </div>
                                            <div class="col-2">
                                                <img class="img-fluid"
                                                    :src="`${apiUrl}/clinics-images/${clinic.presentation.image}`">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label required fw-bold fs-6">
                                        Clinic description<br />
                                        <span class="text-muted"> Max. 400 characters </span>
                                    </label>
                                    <div class="col-lg-8">
                                        <h6>Default / fallback</h6>
                                        <textarea class="form-control mb-2"
                                            v-model="clinicPresentation.description" maxlength="400"
                                            required />

                                        <template v-for="translatedDescription in clinicPresentation.translatedDescription" :key="`desc-${translatedDescription.language}`">
                                            <h6>{{ translatedDescription.language }} translation</h6>
                                            <textarea class="form-control mb-2"
                                                v-model="translatedDescription.description" maxlength="400"
                                                required
                                            />
                                        </template>
                                        
                                        <template
                                            v-for="language in ['EN', 'DE', 'FR', 'IT', 'ES'].filter(language => !clinicPresentation.translatedDescription.map(description => description.language).includes(language))"
                                            :key="`translate-desc-to-${language}`"
                                        >
                                            <a href="#" @click.prevent="() => addTranslatedDescription(language)"
                                                class="btn btn-sm btn-link">
                                                Add {{ language }} translation
                                            </a>{{ language != "ES" ? ' | ' : '' }} 
                                        </template>
                                    </div>
                                </div>

                                <div class="row mb-6">
                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                        <span class="required">Spoken languages</span>
                                    </label>
                                    <div class="col-lg-8">
                                        <v-select v-model="clinicPresentation.spokenLanguages"
                                            :options="iso6391languages" :reduce="(e) => e.code" label="name" multiple>
                                            <template #no-options>Search language...</template>
                                            <template #option="option">
                                                <div class="d-center">{{ option.name }}</div>
                                            </template>
                                            <template #selected-option="option">
                                                <div class="selected d-center">{{ option.name }}</div>
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary">
                                    Save changes
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <template v-if="Array.isArray(doctors)">
                    <div class="separator separator-dashed mb-10"></div>

                    <div class="d-flex flex-row align-items-center justify-content-between mb-4">
                        <h3 class="mb-0">Doctors presentations</h3>
                    </div>

                    <template v-for="doctor of doctors" :key="doctor.id">
                        <h4 class="mb-0">Dr. {{ doctor.firstname }} {{ doctor.lastname }}</h4>
                        <div class="d-flex flex-wrap pt-5 mb-10">
                            <template v-if="!doctor.presentationDraft.approved">
                                <div class="flex-equal">
                                    <h6>⚠️ Presentation pending review</h6>
                                    <img v-if="doctor.presentationDraft.image" class="img-fluid my-2"
                                        style="max-height: 100px;"
                                        :src="`${apiUrl}/doctors-images/${doctor.presentationDraft.image}`">
                                    <span v-else>No image</span>
                                    <p>
                                        <strong>Description</strong><br>
                                        <span
                                            v-if="doctor.presentationDraft && doctor.presentationDraft.description"
                                            v-html="doctor.presentationDraft.description.replace(/\n/g, '<br />')"
                                        />
                                    </p>
                                    <p>
                                        <strong>Experience/background</strong><br>
                                        <span
                                            v-if="doctor.presentationDraft && doctor.presentationDraft.experience"
                                            v-html="doctor.presentationDraft.experience.replace(/\n/g, '<br />')"
                                        />
                                    </p>
                                    <p>
                                        <strong>Spoken languages</strong><br>
                                        {{ doctor.presentationDraft.spokenLanguages.join(', ').toUpperCase() }}
                                    </p>
                                </div>
                                <div class="flex-equal align-self-center">
                                    <button @click.prevent="() => approvePresentation('user', doctor.id)"
                                        class="btn btn-secondary btn-sm d-block mx-auto text-center">
                                        Approve modifications<br>
                                        <i class="bi bi-arrow-right"></i>
                                    </button>
                                </div>
                            </template>
                            <div class="flex-equal">
                                <h6>
                                    ℹ️ Current presentation on website
                                    <small v-if="clinic.presentationDraft.approved">
                                        -
                                        <a href="#" @click.prevent="editDoctorPresentation(doctor.id)" class="btn btn-sm btn-link">
                                            Edit
                                        </a>
                                    </small>
                                </h6>
                                <div v-if="!editingDoctorsPresentations.includes(doctor.id)">
                                    <img v-if="doctor.presentation.image" class="img-fluid my-2" style="max-height: 100px;"
                                    :src="`${apiUrl}/doctors-images/${doctor.presentation.image}`">
                                    <span v-else>No image</span>
                                    <p>
                                        <strong>Description</strong><br>
                                        <span
                                            v-if="doctor.presentation && doctor.presentation.description"
                                            v-html="doctor.presentation.description.replace(/\n/g, '<br />')"
                                        />
                                    </p>
                                    <p
                                        v-for="translatedDescription in doctor.presentation.translatedDescription"
                                        :key="`desc-${translatedDescription.language}`"
                                    >
                                        <strong>Description ({{ translatedDescription.language }})</strong><br>
                                        <span
                                            v-html="translatedDescription.description.replace(/\n/g, '<br />')"
                                        />
                                    </p>
                                    <p>
                                        <strong>Experience/background</strong><br>
                                        <span
                                            v-if="doctor.presentation && doctor.presentation.experience"
                                            v-html="doctor.presentation.experience.replace(/\n/g, '<br />')"
                                        />
                                    </p>
                                    <p
                                        v-for="translatedExperience in doctor.presentation.translatedExperience"
                                        :key="`desc-${translatedExperience.language}`"
                                    >
                                        <strong>Experience/background ({{ translatedExperience.language }})</strong><br>
                                        <span
                                            v-html="translatedExperience.experience.replace(/\n/g, '<br />')"
                                        />
                                    </p>
                                    <p>
                                        <strong>Spoken languages</strong><br>
                                        {{ doctor.presentation.spokenLanguages.join(', ').toUpperCase() }}
                                    </p>
                                </div>
                                <div v-else>
                                    <form class="form" enctype="multipart/form-data" @submit.prevent="saveDoctorPresentation(doctor)">
                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label fs-6 fw-bold">
                                                Picture<br />
                                                <span class="text-muted">
                                                    A picture that represents the doctor
                                                </span>
                                            </label>
                                            <div class="col-lg-8">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <input :disabled="isUploading"
                                                            @change="handleDoctorImageUpload(doctor.id, $event.target.files[0])"
                                                            type="file" class="form-control" accept="image/jpeg,image/png" />
                                                    </div>
                                                    <div class="col-2">
                                                        <img class="img-fluid"
                                                            :src="`${apiUrl}/doctors-images/${doctor.presentation.image}`">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">
                                                Doctor's description<br />
                                                <span class="text-muted"> Max. 400 characters </span>
                                            </label>
                                            <div class="col-lg-8">
                                                <h6>Default / fallback</h6>
                                                <textarea class="form-control mb-2"
                                                    v-model="doctor.presentation.description" maxlength="400"
                                                    required />

                                                <template v-for="translatedDescription in doctor.presentation.translatedDescription" :key="`desc-${translatedDescription.language}`">
                                                    <h6>{{ translatedDescription.language }} translation</h6>
                                                    <textarea class="form-control mb-2"
                                                        v-model="translatedDescription.description" maxlength="400"
                                                        required
                                                    />
                                                </template>
                                                
                                                <template
                                                    v-for="language in ['EN', 'DE', 'FR', 'IT', 'ES'].filter(language => !doctor.presentation.translatedDescription.map(description => description.language).includes(language))"
                                                    :key="`translate-desc-${doctor.id}-to-${language}`"
                                                >
                                                    <a 
                                                        href="#"
                                                        @click.prevent="() => addDoctorTranslatedDescription(doctor, language)"
                                                        class="btn btn-sm btn-link"
                                                    >
                                                        Add {{ language }} translation
                                                    </a>{{ language != "ES" ? ' | ' : '' }}
                                                </template>
                                            </div>
                                        </div>

                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">
                                                Experience/background<br />
                                                <span class="text-muted">
                                                    E.g. clinic's doctor diplomas and experience, max. 200
                                                    characters
                                                </span>
                                            </label>
                                            <div class="col-lg-8">
                                                <h6>Default / fallback</h6>
                                                <textarea class="form-control mb-2"
                                                    v-model="doctor.presentation.experience" maxlength="200"
                                                    required />

                                                <template v-for="translatedExperience in doctor.presentation.translatedExperience" :key="`desc-${translatedExperience.language}`">
                                                    <h6>{{ translatedExperience.language }} translation</h6>
                                                    <textarea class="form-control mb-2"
                                                        v-model="translatedExperience.experience" maxlength="400"
                                                        required
                                                    />
                                                </template>

                                                <template
                                                    v-for="language in ['EN', 'DE', 'FR', 'IT', 'ES'].filter(language => !doctor.presentation.translatedExperience.map(description => description.language).includes(language))"
                                                    :key="`translate-exp-${doctor.id}-to-${language}`"
                                                >
                                                    <a href="#" @click.prevent="() => addDoctorTranslatedExperience(doctor, language)"
                                                        class="btn btn-sm btn-link">
                                                        Add {{ language }} translation
                                                    </a>{{ language != "ES" ? ' | ' : '' }}
                                                </template>
                                            </div>
                                        </div>

                                        <div class="row mb-6">
                                            <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                <span class="required">Spoken languages</span>
                                            </label>
                                            <div class="col-lg-8">
                                                <v-select v-model="doctor.presentation.spokenLanguages"
                                                    :options="iso6391languages" :reduce="(e) => e.code" label="name" multiple>
                                                    <template #no-options>Search language...</template>
                                                    <template #option="option">
                                                        <div class="d-center">{{ option.name }}</div>
                                                    </template>
                                                    <template #selected-option="option">
                                                        <div class="selected d-center">{{ option.name }}</div>
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-primary">
                                            Save changes
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>

                <div class="separator separator-dashed mb-10"></div>

                <div class="d-flex flex-row align-items-center justify-content-between mb-4">
                    <h5 class="mb-0">Before/after pictures submissions:</h5>
                </div>

                <div class="d-flex flex-wrap pt-5 mb-10">
                    <p v-if="!clinicsPictures || !clinicsPictures.length" class="text-muted m-0 mx-auto">
                        No pictures submitted
                    </p>
                    <div v-for="clinicPicture in clinicsPictures" :key="clinicPicture.id"
                        class="my-2 mx-2 clinic-picture-wrapper">
                        <div @click="deleteClinicPicture(clinicPicture)" class="clinic-picture-btn delete">
                            <i class="bi bi-trash mr-1"></i>
                        </div>
                        <div @click="downloadClinicPicture(clinicPicture)" class="clinic-picture-btn download">
                            <i class="bi bi-cloud-arrow-down mr-1"></i>
                        </div>
                        <img class="img-fluid" style="max-height: 120px;" :src="clinicPicture.blobSrc">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import ApiService from "@/core/services/ApiService";
import Iso6391languages from "@/core/constants/Iso-639-1-to-language";
import "vue-select/dist/vue-select.css";

export default {
    props: {
        clinic: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            clinicsPictures: null,
            doctors: null,
            apiUrl: process.env.VUE_APP_API_URL,
			iso6391languages: Iso6391languages,
            clinicPresentation: null,
            isUploading: false,
            editingClinicPresentation: false,
            editingDoctorsPresentations: [],
            doctorsImagesUploading: [],
            doctorsImages: [],
        }
    },
    computed: {
        defaultAddress() {
            return this.clinic.addresses.find(e => e.label == "DEFAULT");
        },
    },
    created() {
        this.fetchClinicsPictures();
        this.fetchClinicsDoctors();
    },
    methods: {
        // Fetch clinics pictures (restricted with JWT header access, src url -> blob url)
        fetchClinicsPictures() {
            ApiService.query(`/clinics-pictures?clinic=${this.clinic.id}&limit=200`)
                .then(({ data }) => {
                    const blobSrcPromises = data.results.map(picture =>
                        ApiService.fetchImageWithAuth(`/clinics-pictures/files/${picture.filename}`)
                    );

                    Promise.all(blobSrcPromises).then(blobSrcPromisesRes => {
                        this.clinicsPictures = data.results.map((picture, idx) => ({
                            ...picture,
                            blobSrc: blobSrcPromisesRes[idx],
                        }));
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // Fetch clinic's doctors (for presentations management)
        fetchClinicsDoctors() {
            ApiService.query(`/users?clinic=${this.clinic.id}&isDoctor=true&limit=200`)
                .then(({ data }) => {
                    this.doctors = data.results;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // Delete the given clinicPicture
        deleteClinicPicture(clinicPicture) {
            ApiService.delete(`/clinics-pictures/${clinicPicture.id}`)
                .then(() => {
                    this.clinicsPictures = this.clinicsPictures.filter(picture => picture.id != clinicPicture.id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // Download the given clinicPicture
        downloadClinicPicture(clinicPicture) {
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = clinicPicture.blobSrc;
            link.download = clinicPicture.originalname;
            document.body.appendChild(link);
            link.click();

            // Firefox workaround with setTimeout waiting
            setTimeout(() => {
                URL.revokeObjectURL(link.href);
                link.parentNode.removeChild(link);
            }, 0);
        },
        approvePresentation(type, itemId) {
            if (type != "clinic" && type != "user") return;
            const responseToastType = type == "clinic" ? "Clinic's" : "Doctor's";
            const apiReqType = type == "clinic" ? "clinics" : "users";

            const config = {
                responseToast: {
                    text: `${responseToastType} presentation successfully updated`,
                }
            };

            ApiService.post(`/${apiReqType}/${itemId}/approve-presentation`, null, config)
                .then(() => {
                    if (type == "clinic") {
                        this.$emit('presentationApproved');
                    } else {
                        this.fetchClinicsDoctors();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
		handleClinicImageUpload(file) {
			this.isUploading = true;
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!allowedTypes.includes(file.type)) {
				alert("Only jpeg and png images are allowed");
				this.isUploading = false;
				return;
			}
			if (file.size > 2 * 1000000) { // Max. 2Mo
				alert("File too large, max. 2Mo");
				this.isUploading = false;
				return;
			}
			this.image = file;
			this.isUploading = false;
		},
        editClinicPresentation() {
            this.clinicPresentation = JSON.parse(JSON.stringify(this.clinic.presentation));
            this.editingClinicPresentation = true;
        },
        saveClinicPresentation() {
			ApiService
				.update("clinics", this.clinic.id, { presentation: this.clinicPresentation }, {
                    responseToast: {
                        text: "Clinic's presentation updated succesfully",
                    },
			    })
				.then(() => {
					if (this.image) {
						const formData = new FormData();
						formData.append("image", this.image);
						ApiService
							.update("clinics", this.clinic.id, formData, {
                                responseToast: {
                                    text: "Clinic image updated successfully.",
                                },
                            })
							.then(() => {
                                this.image = null;
                                this.editingClinicPresentation = false;
                                this.$emit('presentationChanged');
                            });
					} else {
                        this.editingClinicPresentation = false;
                        this.$emit('presentationChanged');
                    }
				});
        },
		handleDoctorImageUpload(doctorId, file) {
            this.doctorsImagesUploading.push(doctorId);
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!allowedTypes.includes(file.type)) {
				alert("Only jpeg and png images are allowed");
                this.doctorsImagesUploading = this.doctorsImagesUploading.filter(e => e != doctorId);
				return;
			}
			if (file.size > 2 * 1000000) { // Max. 2Mo
				alert("File too large, max. 2Mo");
                this.doctorsImagesUploading = this.doctorsImagesUploading.filter(e => e != doctorId);
				return;
			}
            this.doctorsImages = this.doctorsImages.filter(e => e.doctorId != doctorId);
            this.doctorsImages.push({ doctorId, image: file });
            this.doctorsImagesUploading = this.doctorsImagesUploading.filter(e => e != doctorId);
		},
        editDoctorPresentation(doctorId) {
            this.editingDoctorsPresentations.push(doctorId);
        },
        saveDoctorPresentation(doctor) {
            console.log(doctor);
			ApiService
				.update("users", doctor.id, { presentation: doctor.presentation }, {
                    responseToast: {
                        text: "Doctor's presentation updated succesfully",
                    },
			    })
				.then(() => {
                    const doctorImage = this.doctorsImages.find(e => e.doctorId == doctor.id);
                    if (doctorImage) {
                        const formData = new FormData();
                        formData.append("image", doctorImage.image);
                        ApiService.update("users", doctor.id, formData, {
                            responseToast: {
                                text: "Doctor image updated successfully",
                            }
                        }).then((res) => {
                            this.doctorsImages = this.doctorsImages.filter(e => e.doctorId == doctor.id);
                            this.editingDoctorsPresentations = this.editingDoctorsPresentations.filter(e =>
                                e != doctor.id
                            );
                            doctor.presentation = res.data.presentation;
                        });
                    } else {
                        this.editingDoctorsPresentations = this.editingDoctorsPresentations.filter(e => e != doctor.id);
                    }
				});
        },
        addTranslatedDescription(language) {
            this.clinicPresentation.translatedDescription.push({
                language,
                description: "",
            });
        },
        addDoctorTranslatedDescription(doctor, language) {
            doctor.presentation.translatedDescription.push({
                language,
                description: "",
            });
        },
        addDoctorTranslatedExperience(doctor, language) {
            doctor.presentation.translatedExperience.push({
                language,
                experience: "",
            });
        },
    },
};
</script>
  
<style scoped>
.clinic-picture-wrapper {
    position: relative;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.clinic-picture-wrapper .clinic-picture-btn {
    position: absolute;
    top: -9px;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    padding: 3px 6px;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transition: .2s all;
}

.clinic-picture-wrapper:hover .clinic-picture-btn {
    visibility: visible;
    opacity: 1;
}

.clinic-picture-wrapper .clinic-picture-btn.delete {
    left: -7px;
    background: #cc2e2e;
}

.clinic-picture-wrapper .clinic-picture-btn.download {
    right: -7px;
    background: #233cff;
}

.clinic-picture-wrapper .clinic-picture-btn i {
    color: #fff;
}
</style>